import { BASE_URL } from "constants";
import axios from "../axios";

export const createRewardsApi = async (formData) => {
  const response = await axios.post(`${BASE_URL}/reward/create`, formData);
  return response.data;
};

export const updateRewardsApi = async (id, formData) => {
  const response = await axios.put(`${BASE_URL}/reward/update/${id}`, formData);
  return response.data;};


export const getAllSecretRewards = async () => {
  const response = await axios.get(`${BASE_URL}/reward/getAllRewards`, {
    params: { is_secret_reward: true },
  });
  return response.data;
};


  export const deleteRewardService = async(id) => {
    const response = await axios.delete(`${BASE_URL}/reward/delete/${id}`);
    return response.data;
  }