import { COLORS } from "../../utils/material-ui/palette.utils";
import { Grid, IconButton, Typography } from "@mui/material";
import { footerStyles } from "./Footer.style";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

export default function Footer() {
  const classes = footerStyles();

  return (
    <footer className={classes.footer}>
      <Grid
        container
        sx={{
          flexDirection: { sm: "row", xs: "column-reverse" },
          mx: { sm: 10 },
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={6}
          sx={{
            alignItems: { sm: "center", xs: "center" },
            justifyContent: { sm: "start", xs: "center" },
          }}
        >
          <Typography color={COLORS.WHITE} variant="body2" component="div">
            &copy; 2023 GiverBox Inc. All Rights Reserved.
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          sm={6}
          sx={{
            alignItems: { sm: "center", xs: "center" },
            justifyContent: { sm: "end", xs: "center" },
          }}
        >
          <IconButton
            aria-label="Twitter"
            onClick={() => window.open("https://twitter.com/GiverBoxApp")}
          >
            <TwitterIcon sx={{ color: COLORS.WHITE }} />
          </IconButton>
          <IconButton
            aria-label="Instagram"
            onClick={() =>
              window.open("https://www.instagram.com/GiverBoxApp/")
            }
          >
            <InstagramIcon sx={{ color: COLORS.WHITE }} />
          </IconButton>
          <IconButton
            aria-label="Facebook"
            onClick={() => window.open("https://www.facebook.com/GiverBoxApp")}
          >
            <FacebookIcon sx={{ color: COLORS.WHITE }} />
          </IconButton>
          <IconButton
            aria-label="YouTube"
            onClick={() => window.open("https://www.youtube.com/@giverboxapp")}
          >
            <YouTubeIcon sx={{ color: COLORS.WHITE }} />
          </IconButton>
        </Grid>
      </Grid>
    </footer>
  );
}
