import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import useLoadingIndicatorStyles from './LoaderIndicator.style';

const LoadingIndicator = () => {
    const loadingIndicatorClasses = useLoadingIndicatorStyles();

    return (
        <div className={loadingIndicatorClasses.loadingIndicator}>
            <CircularProgress color="primary" />
        </div>
    );
};

export default LoadingIndicator;
