import { BASE_URL } from "constants";
import { instance, userInstance } from "../axios";
import { setUserDataLocalStorage } from "utils/local-storage/get-user-data";

export const signUpAPI = async (formData) => {
  const response = await userInstance.post(`${BASE_URL}/auth/signup`, formData);
  return response.data;
};

export const signInAPI = async (formData) => {
  const response = await userInstance.post(`${BASE_URL}/auth/signin`, formData);
  return response.data;
};

export const getAllUser = async () => {
  const response = await instance.get(`${BASE_URL}/user/list`);
  return response.data;
};

export const addInstagramId = async (formData) => {
  const response = await userInstance.put(`${BASE_URL}/user/update`, formData);
  return response.data;
};

export const getUserInfo = async (userId) => {
  const response = await userInstance.get(
    `${BASE_URL}/user/get-info/${userId}`
  );
  setUserDataLocalStorage(response.data);
  return response.data;
};

export const getUserDetailsFromGoogle = async (userEmail) => {
  const response = await userInstance.get(`${BASE_URL}/user/user-details`, {
    params: { email: userEmail },
  });
  setUserDataLocalStorage(response.data);
  return response.data;
};

export const resetPasswords = async (formData) => {
  const response = await userInstance.post(
    `${BASE_URL}/auth/reset-password`,
    formData
  );
  return response.data;
};

export const editProfileServices = async (userData, token) => {
  const response = await userInstance.put(`${BASE_URL}/user/update`, userData);
  return response.data;
};

export const verifyUserInstagram = async (status, id) => {
  if (id) {
    const response = await userInstance.patch(
      `${BASE_URL}/user/verify-account/${id}`,
      null,
      {
        params: { isInstagramVerified: status },
      }
    );
    return response.data;
  }
};

export const deleteUserAccount = async (id) => {
  const response = await userInstance.delete(`${BASE_URL}/user/delete/${id}`, {
    params: { id: id }
  });
  return response.data;
};