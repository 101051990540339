export const COLORS = {
  BACKGROUND:"#F8FBFF",
  BACKGROUND_DEFAULT: "#FFFFFF",
  BACKGROUND_PAPER: "#FFFFFF",
  BLACK: "#111111",
  BLACK_RGB :"rgba(0, 0, 0, 0.54)",
  BLUE: "#3B70F8",
  BLUE_300: "rgba(101, 33, 229, 0.20)",
  BORDER:"#ECF0F2",
  BORDER_200:"#E2E7F4",
  DANGER: "#CF000F",
  DARK_GREEN: "#16A085",
  DARK_GREY: "#243E63",
  ERROR: "rgb(255,0,0)",
  ERROR_GRADIENT: "#CF000F1A",
  FILTER_SHADOW:"rgba(0, 0, 0, 0.16",
  GREEN: "#097968",
  GREY: "#8C8C8C",
  GREY_100:"#E9E9E9",
  GREY_200: "#f5f8fd",
  GREY_300: "#8F939D",
  GREY_400:"#B0B7C5",
  GREY_DISABLED: "#E2E4EA",
  GREY_TEXT: "#808BA1",
  INFO: "#9FD4F2",
  LIGHT_BLACK: "#141517",
  LIGHT_BLUE: "#255FD2",
  LIGHT_GREEN: "#E3F4F0",
  LIGHT_GREY: "#edf2f7",
  LIGHT_PINK: "#D370FA",
  LIGHT_PURPLE: "#9375F6",
  OFF_WHITE: "#F5F2FF",
  ORANGE: "#FF5733",
  PRIMARY: "#6521E5",
  PRIMARY_400: "#9375F6",
  PRIMARY_DARK: "#3c0d99",
  PRIMARY_LIGHT: "#F7F1FF",
  PURPLE_200:"#E0D3FA",
  RED: "#DD1521",
  SECONDARY: "#ED6F6A",
  SUCCESS: "#007C4B",
  TEXT_DEFAULT: "#364954",
  WARNING: "#EF972C",
  WHITE: "#FFFFFF",
  WHITE_300: "#F5F2FF",
  WHITE_400: "#F0F1F2",
  WHITE_500: "#E8EDFA",
  WHITE_600:"#FFFEFD",
  WHITE_700:"#F8F9FC",
  YELLOW_LIGHT:"#FDEBD0"
};
