export const pathname = [
  "/create-contest",
  "/giveaways-list",
  "/referral-list",
  "/reward-points",
  "/verify-instagram",
  "/user-list",
  "/rules-verified",
  "/user-reward",
  "/rewards-details",
  "/contest-list",
  "/create-rewards",
];

export const navbarPathname = [
  "user-contest",
  "rewards",
  "saved-contest",
  "profile",
  "tutorials",
  "reward-details",
  "coin",
  "ongoing-contests"
];
