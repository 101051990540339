import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import ReactGA from "react-ga";

const Tracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  return null;
};

export default Tracking;
