import * as yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { COLORS } from "../../utils/material-ui/palette.utils";
import { Controller, useForm } from "react-hook-form";
import {
  CustomToaster,
  notifyError,
  notifySuccess,
} from "components/Toaster/Toaster";
import { createRewardsApi } from "rest/rewards.rest";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import React from "react";
import tw from "twin.macro";

export const CreateRewardsPage = () => {
  const navigate = useNavigate();
  const Content = tw.div`m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex  flex-1`;
  const MainContainer = tw.div`p-6 sm:p-12`;

  const schema = yup.object({
    amount: yup.string().required(),
    // imagelink: yup.string().required(),
    name: yup.string().required(),
    points: yup.string().required(),
    rules: yup.string().required(),
    secretReward: yup.boolean().optional(),
    size: yup.string().required(),
    type: yup.string().required(),
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmitHandler = async (values) => {
    delete values.imageLink;
    if (!values.secretReward) {
      values.secretReward = false;
    }
    try {
      await createRewardsApi(values);
      notifySuccess("Contest created successfully!");
      reset();
      navigate("/rewards-details");
    } catch (err) {
      console.log("ee", err);
      notifyError("Error occured while creating contest!");
    }
  };

  return (
    <>
      <AnimationRevealPage>
        <CustomToaster />
        <Content>
          <MainContainer>
            <Box my={5}>
              <Typography fontWeight="500" variant="h5" component="h4">
                Create Rewards For GiverBox
              </Typography>
            </Box>
            <Box>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} md={6}>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ mb: 3 }}
                          label="Name"
                          variant="outlined"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors?.name)}
                          helperText={errors?.name?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <Controller
                      control={control}
                      name="rules"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ mb: 3 }}
                          label="Rules"
                          variant="outlined"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors?.rules)}
                          helperText={errors?.rules?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <Controller
                      control={control}
                      name="type"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ mb: 3 }}
                          label="Type"
                          variant="outlined"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors?.type)}
                          helperText={errors?.type?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <Controller
                      control={control}
                      name="imagelink"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ mb: 3 }}
                          label="Image Link"
                          variant="outlined"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors?.imagelink)}
                          helperText={errors?.imagelink?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <Controller
                      control={control}
                      name="amount"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ mb: 3 }}
                          label="Amount"
                          variant="outlined"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors?.amount)}
                          helperText={errors?.amount?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <Controller
                      control={control}
                      name="points"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ mb: 3 }}
                          label="Points"
                          variant="outlined"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors?.points)}
                          helperText={errors?.points?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <Controller
                      control={control}
                      name="size"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ mb: 3 }}
                          label="Size"
                          variant="outlined"
                          fullWidth
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors?.size)}
                          helperText={errors?.size?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <FormControlLabel
                      control={
                        <Controller
                          name="secretReward"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Checkbox
                              style={{
                                padding: "5px",
                              }}
                              checked={value}
                              value={value}
                              placeholder="Secret Rewards"
                              color="primary"
                              disableRipple
                              onChange={onChange}
                            />
                          )}
                        />
                      }
                      label="Secret Rewards"
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
                <Box>
                  <Button
                    sx={(theme) => ({
                      backgroundColor: `${theme.palette.primary.main} !important`,
                      borderRadius: "12px",
                      color: COLORS.WHITE,
                      margin: "3rem 0",
                      padding: "8px 35px",
                    })}
                    type="submit"
                    variant="contained"
                  >
                    Create Reward
                  </Button>
                </Box>
              </form>
            </Box>
          </MainContainer>
        </Content>
      </AnimationRevealPage>
    </>
  );
};
