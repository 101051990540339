import { createTheme } from "@mui/material/styles";
import { darken, lighten } from "@mui/system";

import { COLORS } from "../../utils/material-ui/palette.utils";

export const theme = createTheme({
  palette: {
    background: {
      default: COLORS.BACKGROUND_DEFAULT,
      paper: COLORS.BACKGROUND_PAPER,
    },
    error: {
      contrastText: COLORS.TEXT_DEFAULT,
      dark: darken(COLORS.ERROR, 0.8),
      light: lighten(COLORS.ERROR, 0.5),
      main: COLORS.ERROR,
    },
    grey: {
      defaultGrey: COLORS.GREY,
    },
    info: {
      dark: darken(COLORS.INFO, 0.8),
      light: lighten(COLORS.INFO, 0.5),
      main: COLORS.INFO,
    },
    primary: {
      dark: darken(COLORS.PRIMARY, 0.8),
      light: lighten(COLORS.PRIMARY, 0.5),
      main: COLORS.PRIMARY,
    },
    secondary: {
      contrastText: COLORS.TEXT_DEFAULT,
      dark: darken(COLORS.PRIMARY, 0.8),
      light: lighten(COLORS.SECONDARY, 0.5),
      main: COLORS.SECONDARY,
    },
    success: {
      dark: darken(COLORS.SUCCESS, 0.8),
      light: lighten(COLORS.SUCCESS, 0.5),
      main: COLORS.SUCCESS,
    },
    text: {
      primary: COLORS.TEXT_DEFAULT,
    },
    warning: {
      dark: darken(COLORS.WARNING, 0.8),
      light: lighten(COLORS.WARNING, 0.5),
      main: COLORS.WARNING,
    },
  },
  typography: {
    body1: {
      fontSize: "14px",
    },
    caption: {
      fontSize: "12px",
    },
    customFontSizes: {
      FS_10: "10px",
      FS_20: "20px",
      FS_22: "22px",
      FS_24: "24px",
      FS_26: "26px",
      FS_28: "28px",
      FS_30: "30px",
      FS_36: "36px",
      FS_38: "38px",
      FS_40: "40px",
    },
    fontFamily: "Poppins, sans-serif",
    h1: {
      fontSize: "42px",
    },
    h3: { fontSize: "34px" },
    h4: { fontSize: "32px" },
    h5: {
      fontSize: "25px",
    },
    h6: {
      fontSize: "28px",
    },
    heading: {
      fontSize: "54px",
    },
    large: {
      fontSize: "62px",
    },
    medium: {
      fontSize: "48px",
    },
    subtitle1: {
      fontSize: "16px",
    },
    subtitle2: {
      fontSize: "18px",
    },
    subtitle3: {
      fontSize: "20px",
    },
  },
});
