import { makeStyles } from "@material-ui/core/styles";

const useLoadingIndicatorStyles = makeStyles({
    loadingIndicator: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        height: '100vh',
        justifyContent: 'center',
        position: 'relative',
    },
});

export default useLoadingIndicatorStyles;
