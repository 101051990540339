import { AxiosInterceptor } from "./axios";
import { ProSidebarProvider } from "react-pro-sidebar";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { createRoot } from "react-dom/client";
import { theme } from "utils/material-ui/theme.utils";
import App from "./App";
import Footer from "components/Footer/Footer";
import Modal from "react-modal";
import Navbar from "./components/Navbar/Navbar";
import React from "react";

Modal.setAppElement("#root");

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ThemeProvider theme={theme}>
    <Router>
      <AxiosInterceptor>
        <ProSidebarProvider>
          <Navbar />
          <App />
        </ProSidebarProvider>
        <Footer />
      </AxiosInterceptor>
    </Router>
  </ThemeProvider>
);
