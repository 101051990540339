import "react-toastify/dist/ReactToastify.css";
import { Box, Grid } from "@mui/material";
import { ContactUs } from "pages/ContactUsPage/ContactUsPage.js";
import { CreateRewardsPage } from "pages/CreateRewardsPage/CreateRewardsPage";
import { Policies } from "pages/Policies";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { pathname } from "constant/Sidebar.constant";
import GlobalStyles from "styles/GlobalStyles.js";
import LoadingIndicator from "../src/components/loader/LoaderIndicator";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import React, { Suspense, lazy, useEffect, useState } from "react";
import ReactGA from "react-ga";
import Tracking from "components/PageCount/PageCount";

const LoginPage = lazy(() => import("pages/Login/Login.js"));
const LandingPage = lazy(() => import("pages/LandingPage"));
const GiveawaysList = lazy(() => import("pages/GiveawaysList.js"));
const CreateContest = lazy(() => import("pages/CreateContest/CreateContest"));
const PricingPage = lazy(() => import("pages/PricingPage/Pricing"));
const ReferralList = lazy(() => import("pages/ReferralList"));
const RewardPointsPage = lazy(() => import("pages/RewardPoints.js"));
const RewardsDetails = lazy(() => import("pages/RewardsDetails"));
const RulesVerifiedList = lazy(() => import("pages/RulesVerifiedList"));
const SignupPage = lazy(() => import("pages/Signup.js"));
const UserList = lazy(() => import("pages/UserList.js"));
const UserRewards = lazy(() => import("pages/UserRewards"));
const VerifyInstagramPage = lazy(() => import("pages/VerifyInstagram"));
const SidebarMenu = lazy(() => import("./components/SidebarMenu/SidebarMenu"));
const UserSignup = lazy(() => import("pages/UserSignup/UserSignup"));
const OtpPage = lazy(() => import("pages/OtpPage/OtpPage"));
const UserContest = lazy(() => import("pages/UserContest/UserContest"));
const Details = lazy(() => import("components/Details/Details"));
const UserLogin = lazy(() => import("pages/UserLogin/UserLogin"));
const HowItWorks = lazy(() => import("pages/HowItWorks/HowItWorks"));
const UserContestDetailPage = lazy(() =>
  import("pages/UserContestDetailPage/UserContestDetailPage")
);
const ResetPasswordsPage = lazy(() =>
  import("pages/ResetPasswordsPage/ResetPasswordsPage")
);
const NewForgetPasswordPage = lazy(() =>
  import("pages/NewPasswordPage/NewPasswordPage")
);
const UpdatedPassowrdPage = lazy(() =>
  import("pages/NewPasswordPage/UpdatedPasswordPage")
);
const UserHomePage = lazy(() => import("pages/UserHomePage/UserHomePage"));
const UserProfile = lazy(() => import("pages/UserProfile/UserProfile"));
const UserCoinsPage = lazy(() => import("pages/CoinScreen/CoinScreen"));
const UserRewardsPage = lazy(() => import("pages/UserRewards/UserRewards"));
const UserRewardDetailsPage = lazy(() =>
  import("pages/UserRewardDetailsPage/UserRewardDetailsPage")
);
const OngoingPage = lazy(() => import("pages/OngoingPage/OngoingPage"));
const YoutubeTutorialPage = lazy(() =>
  import("pages/YoutubeTutorialPage/YoutubeTutorialPage")
);;
const UserSavedContestPage = lazy(() =>
  import("pages/UserSavedContestPage/UserSavedContestPage")
);
const CategoryPage = lazy(() => import("pages/CategoryPage/CategoryPage"))

const TRACKING_ID = "UA-253413434-1";
ReactGA.initialize(TRACKING_ID);
export default function App() {
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const accessToken = url.searchParams.get("access_token");
    if (accessToken) {
      setLoader(true);
      localStorage.setItem("userToken", JSON.stringify(accessToken));
      setLoader(false);
      navigate("/ongoing-contests");
    }
  }, []);

  window.addEventListener("popstate", function (event) {
    if (window.location.search.includes("success=true")) {
      window.location.href = "/user-contest";
    }
  });

  return (
    <>
      <Suspense fallback={<LoadingIndicator />}>
        <GlobalStyles />
        <Tracking />
        {loader && <LoadingIndicator />}

        <Grid display="flex">
          <ToastContainer />
          {pathname.includes(location.pathname) &&
            !location.pathname.startsWith("/details") && (
              <Box mt={11}>
                <SidebarMenu />
              </Box>
            )}

          <Routes>
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/create-contest" element={<CreateContest />} />
            <Route path="/edit-contest/:id" element={<CreateContest />} />
            <Route
              path="/reward-details/:id"
              element={<UserRewardDetailsPage />}
            />
            <Route path="/giveaways-list" element={<GiveawaysList />} />
            <Route path="/referral-list" element={<ReferralList />} />
            <Route path="/policies" element={<Policies />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/reward-points" element={<RewardPointsPage />} />
            <Route path="/verify-instagram" element={<VerifyInstagramPage />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/rules-verified" element={<RulesVerifiedList />} />
            <Route path="/:detailType/:id" element={<Details />} />
            <Route
              path="/contest-detail/:id"
              element={<UserContestDetailPage />}
            />
            <Route path="/user-reward" element={<UserRewards />} />
            <Route path="/rewards-details" element={<RewardsDetails />} />
            <Route path="/user-signup" element={<UserSignup />} />
            <Route path="/otp-page" element={<OtpPage />} />
            <Route path="/user-contest" element={<UserContest />} />
            <Route path="/ongoing-contests" element={<OngoingPage />} />
            <Route path="/category/:type" element={<CategoryPage />} />
            <Route path="/:detailType/:id" element={<Details />} />
            <Route path="/user-login" element={<UserLogin />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/reset-password" element={<ResetPasswordsPage />} />
            <Route path="/password" element={<NewForgetPasswordPage />} />
            <Route path="/saved-contest" element={<UserSavedContestPage />} />
            <Route
              path="/reset-password-confirmation-page"
              element={<UpdatedPassowrdPage />}
            />
            <Route path="/coin" element={<UserCoinsPage />} />
            {/* <Route path="/landing-page" element={<ComingSoon />} /> */}
            <Route exact path="/" element={<LandingPage />} />
            <Route
              exact
              path="/create-rewards"
              element={<CreateRewardsPage />}
            />
            <Route path="/videos" element={<YoutubeTutorialPage />} />
            <Route path="/rewards" element={<UserRewardsPage />} />
            <Route path="/user-home" element={<UserHomePage />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </Grid>
      </Suspense>
    </>
  );
}
