import { Box, Typography } from "@mui/material";
import { pageNotFoundStyles } from "./PageNotFound.style";
import React from "react";
import signup_bg from "../../images/404_bg.png"

function PageNotFound() {
  const illustrationImageSrc = signup_bg;
  const classes = pageNotFoundStyles();

  return (
    <>
      <Box className={classes.errorContainer}>
        <Box>
          <Typography
            textAlign="center"
            fontWeight="700"
            variant="h3"
            component="h3"
          >
            Oops! 404 Error
          </Typography>
          <img src={illustrationImageSrc} alt="404" loading="lazy" />
        </Box>
      </Box>
    </>
  );
}

export default PageNotFound;
