import { BASE_URL } from "constants";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const instance = axios.create({
  baseURL: BASE_URL,
});

const userInstance = axios.create({
  baseURL: BASE_URL,
});

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  let requestInterceptor = null;
  let responseInterceptor = null;
  let requestUserInterceptor = null;
  let responseUserInterceptor = null;

  const initializeAxiosInterceptors = () => {
    if (requestInterceptor != null) {
      instance.interceptors.request.eject(requestInterceptor);
    }

    if (responseInterceptor != null) {
      instance.interceptors.response.eject(responseInterceptor);
    }

    requestInterceptor = instance.interceptors.request.use(async function (
      config
    ) {
      const authUser = JSON.parse(localStorage.getItem("loginUser") || "{}");
      const token = authUser?.accessToken;
      if (token) {
        if (config.headers) {
          config.headers["Authorization"] = `Bearer ${token}`;
        } else {
          config.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
      }

      return config;
    });

    responseInterceptor = instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === 401) {
            navigate("/login");
          }
        }

        throw error;
      }
    );
  };

  const initializeUserAxiosInterceptors = () => {
    if (requestUserInterceptor != null) {
      userInstance.interceptors.request.eject(requestUserInterceptor);
    }

    if (responseUserInterceptor != null) {
      userInstance.interceptors.response.eject(responseUserInterceptor);
    }

    requestUserInterceptor = userInstance.interceptors.request.use(
      async function (config) {
        const token = JSON.parse(localStorage.getItem("userToken") || "{}");
        if (token) {
          if (config.headers) {
            config.headers["Authorization"] = `Bearer ${token}`;
          } else {
            config.headers = {
              Authorization: `Bearer ${token}`,
            };
          }
        }

        return config;
      }
    );

    responseUserInterceptor = userInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === 401) {
            navigate("/user-login");
          }
        }

        throw error;
      }
    );
  };

  useEffect(() => {
    initializeAxiosInterceptors();
    initializeUserAxiosInterceptors();
  }, []);

  return children;
};

export default instance;
export { userInstance, AxiosInterceptor, instance };
