import { ToastContainer, toast } from "react-toastify";

export const notifySuccess = (message) =>
  toast.success(message, {
    autoClose: 5000,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: false,
    pauseOnHover: true,
    position: "top-right",
    progress: undefined,
    theme: "light",
  });

export const notifyError = (message) =>
  toast.error(message, {
    autoClose: 5000,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: false,
    pauseOnHover: true,
    position: "top-right",
    progress: undefined,
    theme: "light",
  });

export const CustomToaster = () => <ToastContainer />;
