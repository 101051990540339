import { COLORS } from "../../utils/material-ui/palette.utils";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const navbarStyles = makeStyles((theme) =>
  createStyles({
    appBarStyles: {
      background: `${COLORS.WHITE} !important`,
      boxShadow: `0px 2px 8px 0px #F0F1F2`,
      padding: ".8rem 4rem",
      [theme.breakpoints.down("xs")]: {
        padding: ".8rem 0",
      },
    },
    box: {
      display: "flex",
      marginBottom: "2rem",
    },
    buttonHover: {
      "&:after": {
        backgroundColor: COLORS.PRIMARY,
        bottom: "0",
        color: COLORS.PRIMARY,
        content: "''",
        height: "2px",
        left: "0",
        position: "absolute",
        transform: "scaleX(0)",
        transformOrigin: "bottom right",
        transition: "transform 0.25s ease-out",
        width: "100%",
      },
      "&:hover": {
        color: COLORS.PRIMARY,
      },
      "&:hover:after ": {
        color: COLORS.PRIMARY,
        transform: "scaleX(1)",
        transformOrigin: "bottom left",
      },
      color: `${COLORS.BLACK} !important`,
      fontWeight: "600 !important",
      margin: "15px !important",
      padding: "5px 0 !important",
    },
    declare_button: {
      backgroundColor: `${COLORS.PRIMARY} !important `,
      color: `${COLORS.WHITE} !important`,
    },
    logo: {
      cursor: "pointer",
      maxWidth: 100,
    },
    logoWithoutText: {
      maxWidth: 100,
    },
    menuList: {
      alignItems: "center",
      display: "flex",
    },
    toolbar: {
      justifyContent: "space-between",
    },
  })
);

export const appBarStyles = {
  background: `${COLORS.WHITE}`,
  // boxShadow: `0px 2px 8px 0px ${COLORS.WHITE_400}`,
  padding: "10px",
};
