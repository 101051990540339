import { COLORS } from "../../utils/material-ui/palette.utils";
import { makeStyles } from "@material-ui/core/styles";
export const footerStyles = makeStyles((theme) => ({
  footer: {
    alignItems: "center",
    backgroundColor: COLORS.PRIMARY,
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 10px",
  },
}));
