import { COLORS } from "utils/material-ui/palette.utils";
import { makeStyles } from "@material-ui/core/styles";
export const contactUsPageStyles = makeStyles(() => ({
  content: {
    flexDirection: "column",
    paddingLeft: "5px",
  },
  textAreaContainer: {
    '&::placeholder': {
      color: '#666',
    },
    "&:focus": {
      borderColor:COLORS.PRIMARY,
      borderWidth:"2px",
      outline: "none",
      
    },
    border: "1px solid #c4c4c4",
    borderRadius: "5px",
    padding: "10px",

    width: "100%",
  },
}));
