import { COLORS } from "utils/material-ui/palette.utils";
import { Controller, useForm } from "react-hook-form";
import {
  CustomToaster,
  notifyError,
  notifySuccess,
} from "components/Toaster/Toaster";
import { TextField, Typography, useTheme } from "@mui/material";
import { contactSchema } from "utils/validation-schema/contact.utils";
import { contactUsPageStyles } from "./ContactUsPage.style";
import { yupResolver } from "@hookform/resolvers/yup";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ContactUsBg from "images/contact-us-bg.svg";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import axios from "../../axios";

export const ContactUs = () => {
  const classes = contactUsPageStyles();
  const theme = useTheme();

  const responsiveStyle = {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginRight:0
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginRight:"4rem"
    },
  };

  const responsiveImageBoxStyle = {
    [theme.breakpoints.down("md")]: {
      display: "none",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "45%",
    },
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(contactSchema),
  });

  const onSubmitHandler = (values) => {
    axios
      .post(`/contact/create`, {
        ...values,
      })
      .then((resp) => {
        notifySuccess("Thanks you for reaching out will get back to you soon.");
      })
      .catch((error) => {
        notifyError("Could not submit form.");
      });
  };

  return (
    <AnimationRevealPage>
      <CustomToaster />
      <Grid
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          justifyContent: "center",
          margin: "8rem 0",
          ...responsiveStyle,
        }}
      >
        <Box sx={responsiveImageBoxStyle}>
          <img src={ContactUsBg} alt="Login Background" />
        </Box>
        <Box padding={2}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Grid item container flexDirection="column" mb={2}>
                <Typography
                  fontWeight="600"
                  data-testid="primary-subheading"
                  variant="h1"
                  mb={2}
                  color={COLORS.LIGHT_BLACK}
                >
                  Feel free to get in touch with us.
                </Typography>
                <Typography
                  data-testid="secondary-subheading"
                  variant="subtitle1"
                  component="div"
                  color={COLORS.DARK_GREY}
                  mb={3}
                >
                  Our team will contact you in 24 hrs.
                </Typography>
              </Grid>

              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      data-testid="email-textfield"
                      sx={{ mb: 3 }}
                      label="Email"
                      type="email"
                      fullWidth
                      variant="outlined"
                      value={value}
                      required
                      onChange={onChange}
                      error={Boolean(errors?.email)}
                      helperText={errors?.email?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="mobileNumber"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      data-testid="mobileNumber-textfield"
                      sx={{ mb: 3 }}
                      label="Mobile Number"
                      variant="outlined"
                      value={value}
                      fullWidth
                      required
                      onChange={onChange}
                      error={Boolean(errors?.mobileNumber)}
                      helperText={errors?.mobileNumber?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="subject"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      data-testid="subject-textfield"
                      sx={{ mb: 3 }}
                      label="Subject"
                      variant="outlined"
                      value={value}
                      required
                      fullWidth
                      onChange={onChange}
                      error={Boolean(errors?.subject)}
                      helperText={errors?.subject?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="message"
                  render={({ field: { onChange, value } }) => (
                    <TextareaAutosize
                      data-testid="message-textarea"
                      label="Message"
                      variant="outlined"
                      placeholder="Enter your query"
                      value={value}
                      minRows={8}
                      required
                      fullWidth
                      onChange={onChange}
                      error={Boolean(errors?.message)}
                      helperText={errors?.message?.message}
                      className={classes.textAreaContainer}
                    />
                  )}
                />
                <Grid container>
                  <Button
                    aria-label="Submit"
                    data-testid="submit-button"
                    sx={(theme) => ({
                      backgroundColor: `${theme.palette.primary.main} !important`,
                      borderRadius: "50px",
                      color: COLORS.WHITE,
                      margin: "10px 0",
                      padding:".5rem 2.5rem",
                      textTransform:"capitalize",
                    })}
                    type="submit"
                    variant="outlined"
                  >
                    Submit
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </AnimationRevealPage>
  );
};
