export const getUserDataLocalStorage = () => {
  const getUserLoggedIndata = localStorage.getItem("user");
  const parsedUserLoggedIndata = JSON.parse(getUserLoggedIndata);
  return parsedUserLoggedIndata;
};

export const setUserDataLocalStorage = (value) => {
  localStorage.setItem("user", JSON.stringify(value));
};

export const setUserMobileNumberLocalStorage = (value) => {
  localStorage.setItem("userMobileNumber", JSON.stringify(value));
};

export const getUserMobileNumberLocalStorage = () => {
  const getUserMobileNumber = localStorage.getItem("userMobileNumber");
  const parsedUserMobileNumber = JSON.parse(getUserMobileNumber);
  return parsedUserMobileNumber;
};
