import { makeStyles } from "@material-ui/core/styles";
export const pageNotFoundStyles = makeStyles((theme) => ({
  errorContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "11rem",
    width: "100%"
  },
}));
