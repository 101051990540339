import { Button, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { COLORS } from "utils/material-ui/palette.utils";
import { getUserDataLocalStorage } from "utils/local-storage/get-user-data";
import { navbarPathname } from "constant/Sidebar.constant";
import { navbarStyles } from "./Navbar.style";
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import GiverBoxCoin from "../../images/giverBox-coin.svg";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Logo from "images/GB-icon-with-text.svg";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import { deleteUserAccount } from "rest/userAuth.rest";

const drawerWidth = "100%";

function Navbar(props) {
  const { window } = props;
  const location = useLocation();
  const userToken = localStorage.getItem("userToken");
  const parsedUserToken = JSON.parse(userToken);
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = navbarStyles();
  const navigate = useNavigate();
  const gaEventTracker = useAnalyticsEventTracker("Contact us");
  const [userData, setUserData] = useState();
  const [navData, setNavData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(false);

  const handleAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userToken");
    navigate("/");
  };

  const handleDeleteAccount = async () => {
    await deleteUserAccount(userData.id)
    localStorage.removeItem("user");
    localStorage.removeItem("userToken");
    navigate("/");
  };
  const isSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );
  const hideMenuList =
    !isSmallScreen &&
    navbarPathname.some((path) => location.pathname.includes(path));

  const LOGGEDIN_NAV_ITEMS = [
    { label: "Home", link: "/ongoing-contests" },
    { label: "Rewards", link: "/rewards" },
    { label: "Saved Contests", link: "/saved-contest" },
    { label: "Youtube Tutorials", link: "/youtube-tutorials" },
    {
      label: "All Contests",
      link: parsedUserToken ? "/user-contest" : "/user-login",
    },
    { label: "Profile", link: "/profile" },
    {
      label: parsedUserToken ? "Logout" : "Login",
    },
  ];
  const NAV_ITEMS = [
    { label: "Home", link: "/#" },
    { label: "Pricing", link: "/pricing" },
    { label: "Policies", link: "/policies" },
    { label: "Contact Us", link: "/contact-us" },
    { label: "How It Works", link: "/how-it-works" },
    {
      label: "All Contests",
      link: parsedUserToken ? "/user-contest" : "/user-login",
    },
    {
      label: parsedUserToken ? "Logout" : "Login",
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (item) => {
    if (item.label === "Login") {
      navigate("/user-login");
    } else if (item.label === "Logout") {
      localStorage.removeItem("user");
      localStorage.removeItem("userToken");
      navigate("/");
    } else {
      navigate(item.link);
      gaEventTracker("call");
    }
  };

  const handleUserDataLocalStorage = async () => {
    const userDataLocalStorage = await getUserDataLocalStorage();
    setUserData(userDataLocalStorage);
  };

  useEffect(() => {
    handleUserDataLocalStorage();
    parsedUserToken ? setNavData(LOGGEDIN_NAV_ITEMS) : setNavData(NAV_ITEMS);
  }, [location]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box display="flex" sx={{ m: 2 }} justifyContent="center">
        <img
          onClick={() => {
            navigate("/#");
          }}
          className={classes.logo}
          src={Logo}
          alt="logo"
          width="100%"
          height="100%"
        />
      </Box>
      <Divider />
      {!hideMenuList && (
        <List>
          {navData.map((item) => (
            <>
              {["Login", "Logout"].includes(item.label) ? (
                <Button
                  aria-label="Click"
                  type="submit"
                  onClick={() => handleClick(item)}
                  className={classes.declare_button}
                  variant="contained"
                >
                  {item.label}
                </Button>
              ) : (
                <ListItem key={item.label} disablePadding>
                  <ListItemButton sx={{ textAlign: "center" }}>
                    <ListItemText>
                      <Link
                        color="inherit"
                        key={item.label}
                        component="button"
                        variant="body2"
                        onClick={() => handleClick(item)}
                        underline="none"
                      >
                        {item.label}
                      </Link>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              )}
            </>
          ))}
        </List>
      )}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar className={classes.appBarStyles} component="nav">
        <Toolbar className={classes.toolbar}>
          <Box sx={{ display: { sm: "block" }, flexGrow: 1 }}>
            {isSmallScreen ? (
              <img
                onClick={() => {
                  navigate("/#");
                }}
                className={classes.logoWithoutText}
                src={Logo}
                alt="logo"
              />
            ) : (
              <img
                onClick={() => {
                  navigate("/#");
                }}
                className={classes.logo}
                src={Logo}
                alt="logo"
              />
            )}
          </Box>

          <Box sx={{ textAlign: "end" }}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {!hideMenuList ? (
            <Box sx={{ display: { sm: "block", xs: "none" } }}>
              {navData.map((item) => (
                <>
                  {["Login", "Logout"].includes(item.label) ? (
                    <Button
                      aria-label="Click"
                      type="submit"
                      onClick={() => handleClick(item)}
                      className={classes.declare_button}
                      variant="contained"
                    >
                      {item.label}
                    </Button>
                  ) : (
                    <Link
                      key={item.label}
                      className={classes.buttonHover}
                      component="button"
                      variant="body2"
                      onClick={() => handleClick(item)}
                      underline="none"
                    >
                      {item.label}
                    </Link>
                  )}
                </>
              ))}
            </Box>
          ) : (
            <>
              <div
                onClick={() => navigate("/coin")}
                style={{
                  alignItems: "center",
                  background: COLORS.WHITE_700,
                  border: `1px solid ${COLORS.BORDER_200}`,
                  borderRadius: "24px",
                  display: "flex",
                  marginLeft: "1rem",
                  padding: ".4rem .7rem",
                  cursor: "pointer",
                }}
              >
                <img width={24} height={24} src={GiverBoxCoin} alt="coin" />
                <Typography paddingLeft=".6rem" color={COLORS.LIGHT_BLACK}>
                  {userData?.points}
                </Typography>
              </div>
              {!isSmallScreen &&
                <>
                  <IconButton
                    color="black"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleAnchor}
                    sx={{ marginLeft: '1rem', position: 'relative' }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{ top: '8%' }}
                    anchorOrigin={{
                      vertical: 'top', // Position the menu below the icon
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    <MenuItem onClick={handleDeleteAccount}>Delete Account</MenuItem>
                  </Menu>
                </>
              }
            </>
          )}
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            display: { sm: "none", xs: "block" },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Navbar;
